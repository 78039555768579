<template>
  <div class="notice-manage">
    <div class="search">
      <div class="search-fields">
        <div class="search-field">
          <div class="search-field-label">
            分类:
          </div>
          <div class="search-field-value">
            <el-select v-model="form.categoryId" placeholder="请选择" size="small">
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="search-field">
          <div class="search-field-label">
            标题:
          </div>
          <div class="search-field-value">
            <el-input v-model="form.title" placeholder="请输入标题名称" size="small" />
          </div>
        </div>
        <div class="search-field">
          <div class="search-field-label">
            创建时间:
          </div>
          <div class="search-field-value">
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </div>
        </div>
        <div class="search-field">
          <div class="search-field-label">
            创建人:
          </div>
          <div class="search-field-value">
            <el-input v-model="form.createdBy" placeholder="请输入姓名" size="small" />
          </div>
        </div>
      </div>
      <div class="search-action">
        <el-button type="primary" size="small">
          查询
        </el-button>
        <el-button plain size="small">
          重置
        </el-button>
      </div>
    </div>
    <div class="container">
      <div class="table-top">
        <el-button type="primary" size="small" @click="gotoDetail">
          新建
        </el-button>
        <el-button plain size="small" @click="dialogVisible = true">
          维护分类
        </el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="date"
          label="日期"
          width="180"
        />
        <el-table-column
          prop="date"
          label="日期"
          width="180"
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleClick(scope.row)">
              <i class="el-icon-more" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="维护分类" :visible.sync="dialogVisible" custom-class="notice-manage-dialog" width="600px">
      <div class="dialog-content">
        <div v-for="(item, index) in noticeList" :key="index" class="notice-item">
          <el-input v-model="item.name" placeholder="" size="small" style="width:90%" />
          <i class="el-icon-delete" @click="deCategory(index)" />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addCategory">
          新 建
        </el-button>
        <div class="footer-right">
          <el-button @click="dialogVisible = false">
            取 消
          </el-button>
          <el-button type="primary" @click="changeCategory">
            确 定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getNoticeList, getCategory, changeCategory
} from '@/api/notice';
export default {
  data () {
    return {
      time: '',
      typeList: [],
      noticeList: [],
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      form: {
        categoryId: '',// 分类id
        createdBy: '',
        beginCreateDate: '',
        endCreateDate: '',
        title: '',
      },
      dialogVisible: false,
    };
  },
  mounted () {
    this.init();
    this.getCategory();
  },
  methods: {
    async init () {
      const query = this.form;
      const res = await getNoticeList(query);
      console.log(res);
    },
    async getCategory () {
      const res = await getCategory();
      this.typeList = res;
      this.noticeList = JSON.parse(JSON.stringify(res));
    },
    addCategory () {
      this.noticeList.push({
        name: '',
        id: null,
      });
    },
    deCategory (index) {
      this.noticeList.splice(index, 1);
    },
    async changeCategory () {
      const query = this.noticeList;
      const res = await changeCategory(query);
      console.log(res);
    },
    gotoDetail () {
      this.$router.push({ path: '/notice-detail' });
    },
  },
};
</script>
<style lang="scss">
.notice-manage {
  .notice-manage-dialog {
    .dialog-content {
      height: 368px;
      overflow: auto;
      padding-right: 10px;
    }
    .notice-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      i {
        margin-left: auto;
        font-size: 20px;
        cursor: pointer;
      }
    }
    .el-dialog__footer {
      padding: 0px;
      box-sizing: border-box;
      .dialog-footer {
        border-top: 1px solid #e9e9e9;
        padding: 12px;
        display: flex;
        .footer-right {
          display: flex;
          margin-left: auto;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.notice-manage {
  background-color: #fff;
  .search {
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    display: flex;
    &-fields {
      flex: 1;
      padding-left: 24px;
      display: flex;
      flex-wrap: wrap;
      padding-right: 170px;
      .search-field {
        align-items: center;
        margin: 0 24px 14px 0;
        min-width: 300px;
        display: flex;
        &-label {
          margin-right: 8px;
          width: 90px;
          text-align: right;
          font-size: 14px;
          color: #666;
        }
        &-value {
          flex: 1;
        }
      }
    }
    &-action {
      position: absolute;
      top: 14px;
      right: 24px;
    }
  }
  .container {
    padding: 24px;
    .table-top {
      margin-bottom: 12px;
    }
  }
}
</style>